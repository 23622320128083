import { useCallback, useState } from 'react';
import cn from 'classnames';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';

import {
  AuthLogoIcon, ErrorIcon, TwitterIcon,
  LinkedinSmallIcon, YoutubeIcon, FacebookIcon, DownloadIcon,
  ArrowLeftIcon, RefreshIcon, LogoLightIcon,
  EmailResultsIcon, CupOfTeaIcon,
} from 'assets/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromSquare } from '@fortawesome/pro-regular-svg-icons';

import { routes } from 'routes';

import { userApi } from 'resources/user';
import { monitorApi } from 'resources/monitor';
import { mxrightApi } from 'resources/mxright';

import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import ScoreCircle from 'components/ScoreCircle';
import Speedometer from 'components/Speedometer';
import AuthMenu from 'components/AuthMenu';
import Button from 'components/Button';
import ShareReportModal from 'components/ShareReportModal';
import CreateAccountHeader from 'components/CreateAccountHeader';

import Header from 'layouts/authorized/components/Header';

import useWindowDimensions from 'hooks/useWindowDimensions';

import GroupResultSection from 'pages/Results/components/GroupResultSection';

import { getServiceGroupsData } from 'pages/Results';

import ReportHistorySelect from './components/ReportHistorySelect';

import './styles.scss';

const MAX_RETRY_ATTEMPTS = 5;

const MonitorResults = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { data: currentUser } = userApi.useCurrentUser();

  const serviceGroupsData = getServiceGroupsData({ user: currentUser });

  const [activeItem, setActiveItem] = useState(serviceGroupsData[0].id);
  const [isShareReportModalVisible, setIsShareReportModalVisible] = useState(false);

  const [isHistoryLoaded, setIsHistoryLoaded] = useState(true);
  const [selectedCheckHistoryItemId, setSelectedCheckHistoryItemId] = useState(location.hash
    ? +location.hash.replace('#', '')
    : null);
  const [isFakeLoading, setIsFakeLoading] = useState(false);

  const [retryAttempt, setRetryAttempt] = useState(0);

  const [errorMessage, setErrorMessage] = useState({
    title: null,
    description: null,
    status: null,
  });
  const [isInvalid, setIsInvalid] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 1250;

  const monitorCheckListByIdData = monitorApi.useMonitorCheckListById(params.checkId);

  let score = monitorCheckListByIdData?.data;
  const refetchScore = monitorCheckListByIdData?.refetch;

  const {
    data: checkHistoryItems,
    isFetching: isCheckHistoryItemsLoading,
    isError,
    refetch: refetchCheckHistoryItems,
  } = monitorApi.useCheckHistoryItems(selectedCheckHistoryItemId, {
    onError: (error) => {
      setErrorMessage({
        title: error.data['hydra:title'],
        description: error.data['hydra:description'],
        status: error.status,
      });

      if (error.status === 425) {
        const newRetryAttempt = retryAttempt + 1;

        if (newRetryAttempt <= MAX_RETRY_ATTEMPTS) {
          setRetryAttempt(newRetryAttempt);

          setTimeout(() => {
            refetchCheckHistoryItems();
          }, 1.8 ** (newRetryAttempt) * 1000);
        }

        return;
      }

      if (error.status === 422 || error.status === 403) {
        setIsInvalid(true);

        return;
      }

      if (error.status === 410) {
        setIsExpired(true);
      }

      if (error.status === 417) {
        setRetryAttempt(MAX_RETRY_ATTEMPTS);
      }
    },
  });

  const { data: menuItems } = mxrightApi.useMenuItemsList(score?.item);
  const { data: meters } = mxrightApi.useMetersList(score?.item);
  const { data: commonReportData } = mxrightApi.useCommonData(score?.item);
  const {
    mutate: updateCheckHistoryItem,
    isLoading: isUpdateCheckHistoryItemLoading,
  } = monitorApi.useUpdateCheckHistoryItem();
  const {
    mutateAsync: updateMonitorCheck,
    isLoading: isUpdateMonitorCheckLoading,
  } = monitorApi.useUpdateMonitorCheck();

  if (isFakeLoading || isCheckHistoryItemsLoading) {
    score = null;
  } else if (location.hash) {
    score = checkHistoryItems || null;
  } else {
    score = isHistoryLoaded ? (checkHistoryItems || score) : null;
  }

  const isErrorState = retryAttempt === MAX_RETRY_ATTEMPTS || (isError && (isInvalid || isExpired));

  const onSidebarItemClick = useCallback((serviceGroupName) => {
    setActiveItem(serviceGroupName);
  }, []);

  const onNavigateToMonitors = useCallback(() => {
    navigate(routes.dashboard.path);
  }, [navigate]);

  const onNavigateToEmailMonitors = useCallback(() => {
    navigate(routes.emailInfrastructure.path);
  }, [navigate]);

  const onShareIconClick = useCallback(() => {
    setIsShareReportModalVisible(true);
  }, []);

  const onHistoryItemSelected = useCallback((checkHistoryId) => {
    setIsFakeLoading(true);
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 250);

    setSelectedCheckHistoryItemId(checkHistoryId);

    const currentUrl = window.location.href;

    if (currentUrl.includes('#')) {
      const oldCheckHistoryId = currentUrl.slice(currentUrl.indexOf('#') + 1);

      if (oldCheckHistoryId !== checkHistoryId) {
        window.location.replace(`${currentUrl.substring(0, currentUrl.indexOf('#'))}#${checkHistoryId}`);
      }
    } else {
      const url = `${window.location.href}#${checkHistoryId}`;

      if (window.location.href !== url) {
        window.location.replace(`${window.location.href}#${checkHistoryId}`);
      }
    }

    setIsHistoryLoaded(true);
  }, []);

  const onRefresh = useCallback(() => {
    if (location.hash) {
      refetchCheckHistoryItems();
    } else {
      refetchScore();
    }
  }, [location.hash, refetchCheckHistoryItems, refetchScore]);

  const onShareSwitchChange = useCallback(async (isShare) => {
    if (isShare && !monitorCheckListByIdData.data.shared) {
      await updateMonitorCheck({
        id: params.checkId,
        shared: true,
      });
    }

    updateCheckHistoryItem({
      id: selectedCheckHistoryItemId,
      shared: isShare,
    });
  }, [updateCheckHistoryItem, selectedCheckHistoryItemId, params.checkId,
    monitorCheckListByIdData?.data?.shared, updateMonitorCheck]);

  const ShareIcon = () => (
    <IconButton onClick={onShareIconClick}>
      <FontAwesomeIcon
        icon={faArrowUpFromSquare}
        fontSize={16}
        {...(commonReportData?.shared ? {
          color: '#17EDC3',
        } : {
          fillOpacity: 0.56,
        })}
      />
    </IconButton>
  );

  return (
    <div className="monitor-results-wrapper">
      {isShareReportModalVisible && commonReportData && (
      <ShareReportModal
        open={isShareReportModalVisible}
        onClose={() => setIsShareReportModalVisible(false)}
        linkToShare={window.location.href}
        toEmail={commonReportData.toEmail}
        isShareEnabled={commonReportData.shared}
        onSwitchChange={onShareSwitchChange}
        isSwitchDisabled={isUpdateMonitorCheckLoading || isUpdateCheckHistoryItemLoading}
        isOwner={commonReportData.ownedByMe}
      />
      )}

      {!mobileWidth && (
      <div className={cn('sidebar', 'scroll', !currentUser && 'sidebar-no-user')}>
        <AuthLogoIcon className="auth-logo" onClick={onNavigateToMonitors} />

        <div className="line" />

        <div className="score-circle-wrapper">
          <ScoreCircle
            mxrScoreTotal={commonReportData ? 100 : undefined}
            mxrScoreTotalPass={commonReportData?.totalScore}
            ratingColor={commonReportData?.rating?.color}
            ratingLabel={commonReportData?.rating?.label}
            blured={isError}
          />
        </div>

        {!isError && (
        <>
          <div className="email-for-container">
            {commonReportData?.fromEmail && (
            <p>
              Test for
              {' '}
              {commonReportData.fromEmail}
            </p>
            )}

            <ReportHistorySelect
              onSelected={onHistoryItemSelected}
              checkHistoryItem={checkHistoryItems}
              show={!!commonReportData?.ownedByMe}
            />
          </div>

          {commonReportData && (
          <div className="share-wrapper">
            {(commonReportData.ownedByMe || commonReportData.shared) && (
              <ShareIcon />
            )}

            <IconButton onClick={() => { window.print(); }}>
              <DownloadIcon />
            </IconButton>
          </div>
          )}

          <div className="menu">
            {menuItems?.map((menuItem) => {
              const serviceGroup = serviceGroupsData.find(({ id }) => id === menuItem.id);

              return (
                <HashLink
                  to={`#${menuItem.id}`}
                  smooth
                  onClick={() => onSidebarItemClick(menuItem.id)}
                  key={menuItem.id}
                >
                  <div className={cn('sidebar-item', activeItem === menuItem.id && 'active-item')}>
                    <div>
                      {serviceGroup.sidebarIcon}
                      <p>{menuItem?.name}</p>
                    </div>

                    {!!menuItem.numFailed && (
                      <div className="error-counter">
                        <p>{menuItem.numFailed}</p>
                      </div>
                    )}
                  </div>
                </HashLink>
              );
            })}
          </div>
        </>
        )}
      </div>
      )}

      {!currentUser && !mobileWidth && (
      <CreateAccountHeader title="To monitor this email addresses and more, register for a Free Account" />
      )}

      <div className="results-content">
        {/* Print version starts */}
        <div className="print-header">
          <LogoLightIcon />
          <p>Email Deliverability Tools</p>
        </div>
        <div className="print-info">
          <p>MX Test for {commonReportData?.fromEmail}</p>
          <p>{moment(commonReportData?.resultAt).format('MM/DD/YYYY [at] h:mm:ss A')}</p>
        </div>
        {/* Print version ends */}

        {!!currentUser && !mobileWidth && (
        <header className="header">
          <div className="back-wrapper" onClick={onNavigateToEmailMonitors} aria-hidden="true">
            <ArrowLeftIcon />

            <p>Back to Monitors</p>
          </div>

          <div>
            <AuthMenu />
          </div>
        </header>
        )}

        {mobileWidth && (
        <div>
          <Header mobile hideMenu />

          <div className="circle-content-wrapper">
            <div className="back-wrapper" onClick={onNavigateToEmailMonitors} aria-hidden="true">
              <ArrowLeftIcon />

              <p>Back to Monitors</p>
            </div>

            <div className="score-circle-wrapper">
              <ScoreCircle
                mxrScoreTotal={commonReportData ? 100 : undefined}
                mxrScoreTotalPass={commonReportData?.totalScore}
                ratingColor={commonReportData?.rating?.color}
                ratingLabel={commonReportData?.rating?.label}
                blured={isError}
              />
            </div>

            <div className="email-for-container">
              {commonReportData?.fromEmail && (
              <p>
                Test for
                {' '}
                {commonReportData.fromEmail}
              </p>
              )}

              {commonReportData?.ownedByMe && (
              <ReportHistorySelect
                className="mobile-report-hisotry-select"
                onSelected={onHistoryItemSelected}
                checkHistoryItem={checkHistoryItems}
                show={!!commonReportData?.ownedByMe}
              />
              )}
            </div>

            {commonReportData && (
            <div className="share-wrapper">
              {(commonReportData.ownedByMe || commonReportData.shared) && (
                <ShareIcon />
              )}
              <IconButton onClick={() => { window.print(); }}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </div>
            )}
          </div>
        </div>
        )}

        {!!meters?.length && !isError && (
        <div className={cn('create-account-and-speedometers', !currentUser && 'create-account-and-speedometers-no-account')}>
          <div className={cn('speedometer-container', currentUser && 'speedometer-container-authorized')}>
            {meters.map((item) => (
              <Speedometer
                key={item.id}
                serviceGroup={item.name}
                passTestsCount={item.numPassed}
                totalTestsCount={item.numTotal}
              />
            ))}
          </div>
        </div>
        )}

        {!isInvalid && !isExpired && !score?.results?.length
        && retryAttempt !== MAX_RETRY_ATTEMPTS && (
        <div className="loading-placeholder">
          <CupOfTeaIcon />

          <p>Waiting for your email</p>

          <p>
            If you have not sent the email to <span>{params.email}</span> yet, please do so now.
          </p>
        </div>
        )}

        {isError && (isInvalid || retryAttempt === MAX_RETRY_ATTEMPTS || isExpired) && (
        <div className="error-placeholder">
          <ErrorIcon />

          <p>{errorMessage.title}</p>

          <p>{errorMessage.description}</p>

          {errorMessage.status === 425 && (
          <Button variant="outlined" onClick={() => navigate(0)}>
            Refresh
          </Button>
          )}
        </div>
        )}

        {score?.results?.length && (
        <div>
          {menuItems?.map((menuItem) => {
            const groupResults = serviceGroupsData.find(({ id }) => id === menuItem.id);

            const results = score?.results.filter((item) => item.groupName === menuItem.id);

            return (
              <div
                className={cn('group-results-wrapper', 'pagebreak')}
                key={menuItem.id}
                id={menuItem.id}
              >
                <div className="group-results-header">
                  {groupResults.resultsIcon || <EmailResultsIcon />}

                  <div className="group-results-header-label">
                    <p>{menuItem.name}</p>
                    {groupResults.description && groupResults.description()}
                  </div>
                </div>

                {!!results?.length && (
                <div className="group-results-section-wrapper">
                  {results.map((item) => (
                    <GroupResultSection
                      key={`${menuItem.id}-${item.title}`}
                      groupResultsSection={item}
                      groupName={menuItem.id}
                    />
                  ))}
                </div>
                )}
              </div>
            );
          })}
        </div>
        )}

        {/* Print version starts */}
        <div className="print-view-report">
          <p>View the report:</p>
          <Link
            href={window.location.href}
            underline="hover"
            color="#009688"
          >
            {window.location.href}
          </Link>
        </div>
        <div className="print-footer">
          <LogoLightIcon />

          <p>© 2022 MX Right. All rights reserved.</p>

          <p>www.mxright.com</p>
        </div>
        {/* Print version ends */}
      </div>

      <footer>
        <div>
          <p className="rights">© {moment().year()} MX Right. All rights reserved.</p>
          {/* <a href="https://help.mxright.com" target="_blank" rel="noopener noreferrer"><p>Knowledge Base</p></a> */}
          <a href="https://api.mxright.com/v2/docs?ui=re_doc" target="_blank" rel="noopener noreferrer">
            <p>MX Right API</p>
          </a>
          <a href="https://mxright.com/privacy-policy" target="_blank" rel="noopener noreferrer"><p>Privacy Policy</p></a>
          <a href="https://mxright.com/terms" target="_blank" rel="noopener noreferrer"><p>Terms</p></a>
        </div>

        <div className="social-container">
          {/* <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><TwitterIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><LinkedinSmallIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><YoutubeIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><FacebookIcon /></IconButton> */}
        </div>
      </footer>
    </div>
  );
};

export default MonitorResults;
