import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { faPen, faUserMinus, faXmark } from '@fortawesome/pro-regular-svg-icons';

import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

import { userApi } from 'resources/user';

import DeleteModal from 'components/DeleteModal';

import { USER_ROLES } from 'resources/user/user.constants';

import uiNotificationService from 'services/uiNotificatuion.service';

import generateLinearGradientitem, { getGreyGradient } from 'helpers/getRandomGradient';

import { getUserInitials } from 'pages/Profile';

import AddOrEditUserModal from '../AddOrEditUserModal';

import './styles.scss';

const getRole = (roles) => {
  if (roles.includes(USER_ROLES.ADMIN)) {
    return USER_ROLES.ADMIN;
  }

  return USER_ROLES.USER;
};

const UserItem = ({ item }) => {
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);

  const { mutate: removeUser, isLoading: isRemoveUserLoading } = userApi.useRemoveConnectedUser();

  const isPending = item.status === 'invited';

  const userRole = getRole(item.roles);
  const upperCaseLabel = userRole.substring(userRole.lastIndexOf('_') + 1);
  const userRoleLabel = upperCaseLabel[0] + upperCaseLabel.slice(1).toLowerCase();

  return (
    <div className="user-wrapper">
      {isArchiveModalVisible && (
      <DeleteModal
        title="Are you sure you want to archive this user?"
        open={isArchiveModalVisible}
        onClose={() => setIsArchiveModalVisible(false)}
        onDelete={() => {
          removeUser(item.emailAddress, {
            onSuccess: () => {
              uiNotificationService.showSuccess('User has been archived successfully');
            },
          });
        }}
        isDeleteLoading={isRemoveUserLoading}
      />
      )}
      {isEditUserModalVisible && (
      <AddOrEditUserModal
        open={isEditUserModalVisible}
        onClose={() => setIsEditUserModalVisible(false)}
        user={{ ...item, role: userRole }}
        className="add-or-edit-user-modal"
      />
      )}

      <div className={cn('user-item', isPending && 'user-item-pending')}>
        <div className="name-wrapper">
          <div className="name-icon-wrapper">
            <Avatar sx={{
              background: isPending ? getGreyGradient() : generateLinearGradientitem(item.emailAddress),
              width: '40px',
              height: '40px',
              color: isPending ? 'rgba(255, 255, 255, 0.7)' : 'black',
            }}
            >
              {getUserInitials({
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.emailAddress,
              })}
            </Avatar>
          </div>

          <p>{item.firstName} {item.lastName}</p>
        </div>

        <div className="email-wrapper">
          <p>{item.emailAddress}</p>
        </div>

        <div className="role-wrapper">
          <p>{userRoleLabel}</p>
        </div>

        <div className="invitation-wrapper">
          <p>{isPending ? 'Invitation sent' : ''}</p>
        </div>

        <div className="item-actions">
          {isPending && (
          <>
            <Tooltip onClick={() => null} title="Resend invitation" placement="top">
              <FontAwesomeIcon icon={faRotateRight} color="rgba(255, 255, 255, 0.56)" />
            </Tooltip>
            <Tooltip onClick={() => null} title="Delete" placement="top">
              <FontAwesomeIcon icon={faXmark} color="rgba(255, 255, 255, 0.56)" size="lg" />
            </Tooltip>
          </>
          )}
          {!isPending && (
          <>
            <Tooltip onClick={() => setIsEditUserModalVisible(true)} title="Edit" placement="top">
              <FontAwesomeIcon icon={faPen} color="rgba(255, 255, 255, 0.56)" />
            </Tooltip>
            <Tooltip onClick={() => setIsArchiveModalVisible(true)} title="Archive" placement="top">
              <FontAwesomeIcon icon={faUserMinus} color="rgba(255, 255, 255, 0.56)" />
            </Tooltip>
          </>
          )}
        </div>

      </div>

      <div className="line" />
    </div>
  );
};

UserItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default UserItem;
