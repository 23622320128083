import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import uiNotificationService from 'services/uiNotificatuion.service';

import { routes } from 'routes';

import { connectivityApi } from 'resources/connectivity';

import CircularProgress from '@mui/material/CircularProgress';

import './styles.scss';

const EmailAccountCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // To avoid multiple requests
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { mutate: connectGoogleConnectivity } = connectivityApi.useConnectivityGoogleConnect();
  const { mutate: connectO365Connectivity } = connectivityApi.useConnectivityO365Connect();

  const navigateToEmailAccounts = useCallback(() => {
    navigate(routes.emailAccounts.path);
  }, [navigate]);

  useEffect(() => {
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (errorDescription) {
      uiNotificationService.showError(errorDescription);

      return;
    }

    const state = searchParams.get('state');
    const code = searchParams.get('code');

    let type = null;

    if (state === 'o365') {
      type = 'o365';
    } else {
      type = 'google';
    }

    if (isRequestSent) {
      navigateToEmailAccounts();

      return;
    }

    switch (type) {
      case 'o365':
        if (error) {
          navigateToEmailAccounts();

          return;
        }

        setIsRequestSent(true);

        connectO365Connectivity(code, {
          onSettled: () => {
            navigateToEmailAccounts();
          },
        });
        break;

      case 'google':
        if (error) {
          navigateToEmailAccounts();

          return;
        }

        setIsRequestSent(true);

        connectGoogleConnectivity(code, {
          onSettled: () => {
            navigateToEmailAccounts();
          },
        });
        break;

      default:
        break;
    }
  }, [connectGoogleConnectivity, searchParams, connectO365Connectivity,
    navigate, isRequestSent, navigateToEmailAccounts]);

  return (
    <div className="email-account-callback-wrapper">
      <CircularProgress className="loader" />
    </div>
  );
};

export default EmailAccountCallback;
