import { useMutation, useQuery } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useSubscriptionTemplates() {
  const itemsPerPage = 50;

  const getSubscriptionTemplatess = () => api.get('/v2/subscription-templates', {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get('/v2/subscription-templates', {
          page,
          itemsPerPage,
        })));

      const allSubscriptionTemplates = [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];

      const allSubscriptionTemplatesWithPriceData = await Promise.all(allSubscriptionTemplates
        .map(async (subscriptionTemplate) => {
          const subscriptionTemplateData = await api.get(`/v2/subscription-templates/${subscriptionTemplate.id}`);

          return {
            ...subscriptionTemplate,
            ...subscriptionTemplateData,
          };
        }));

      return allSubscriptionTemplatesWithPriceData;
    });

  return useQuery(['subscription.templates'], getSubscriptionTemplatess);
}

export function useSubscription(id) {
  const getSubscription = () => (id ? api.get(`/v2/subscriptions/${id}`) : null);

  return useQuery(['subscription', { id }], getSubscription);
}

export function useCreateSubscription() {
  const createSubscription = ({
    accountIri,
    templateIri,
    annual,
  }) => api.post('/v2/create-subscription', {
    account: accountIri,
    template: templateIri,
    annual,
  });

  return useMutation(createSubscription);
}

export function useSubscriptionTemplate(id) {
  const getSubscriptionTemplate = () => (id ? api.get(`/v2/subscription-templates/${id}`) : null);

  return useQuery(['subscription.template', id], getSubscriptionTemplate);
}

export function useSubscriptionPayments(ids) {
  const getSubscriptionPayments = async () => {
    if (!ids) {
      return null;
    }

    const payments = await Promise.all(ids.map(async (id) => {
      const payment = await api.get(`/v2/payments/${id}`);

      const recieptLink = await api.get(`/v2/receipt-link/${payment.chargeId}`);

      return {
        ...payment,
        receiptUrl: recieptLink.link,
      };
    }));

    return payments;
  };

  return useQuery(['subscription.payments', ids], getSubscriptionPayments);
}

export function useCustomerPortal(stripeCustomerId) {
  const getCustomerPortal = () => (stripeCustomerId ? api.get(`/v2/customer-portal/${stripeCustomerId}`) : null);

  return useQuery(['subscription.customerPortal', stripeCustomerId], getCustomerPortal);
}

export function useChangeSubscription() {
  const changeSubscription = ({
    templateIri,
    annual,
  }) => api.patch('/v2/change-subscription', {
    template: templateIri,
    annual,
  });

  return useMutation(changeSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('account');
      queryClient.invalidateQueries('subscription');
    },
  });
}
