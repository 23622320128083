import React from 'react';
import PropTypes from 'prop-types';

import { CrossIcon } from 'assets/icons';

import MUIModal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import './styles.scss';

const Modal = ({
  children,
  open,
  onClose,
  title,
  disableEscapeKeyDown,
  style,
}) => (
  <MUIModal
    open={open}
    onClose={onClose}
    disableEscapeKeyDown={disableEscapeKeyDown}
  >
    <div className="modal-container scroll" style={style}>
      <div className="title-wrapper">
        <p style={{ zIndex: 1 }}>{title}</p>

        <IconButton onClick={onClose}>
          <CrossIcon className="cross-icon" style={{ zIndex: 1 }} />
        </IconButton>
      </div>

      {children}
    </div>
  </MUIModal>
);

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  disableEscapeKeyDown: PropTypes.bool,
  style: PropTypes.shape(),
};

Modal.defaultProps = {
  disableEscapeKeyDown: false,
  style: null,
};

export default React.memo(Modal);
