import { useQuery, useMutation } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

import setSecondsToWait from 'pages/EmailConfirmation/helpers/setSecondsToWait';

export function useCurrentUser() {
  const getCurrentUser = () => {
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');

    if (!authToken || !userId) {
      return null;
    }

    return api.get(`/v2/users/${userId}`);
  };

  return useQuery(['currentUser'], getCurrentUser);
}

export function useUser() {
  const getUser = (id) => api.get(`/v2/users/${id}`);

  return useMutation(getUser, {
    onSuccess: (data) => {
      queryClient.setQueryData(['currentUser'], data);

      localStorage.removeItem('registrationEmail');
      localStorage.removeItem('waitingForEmailConfirmation');
    },
  });
}

export function useLogout() {
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  return useMutation(logout, {
    onSuccess: () => {
      queryClient.setQueryData(['currentUser'], null);
    },
  });
}

export function useRegistration() {
  const getRegistrationData = ({ email, password }) => api.post('/v2/user/registration', { email, plainPassword: password });

  return useMutation(getRegistrationData, {
    onSuccess: (data) => {
      localStorage.setItem('waitingForEmailConfirmation', true);
      localStorage.setItem('registrationEmail', data.email);

      setSecondsToWait(data.secondsToWait);
    },
  });
}

export function useResendEmail() {
  const resendEmail = (email) => api.post('/v2/user/resend-email', { email });

  return useMutation(resendEmail, {
    onSuccess: (data) => {
      setSecondsToWait(data.secondsToWait);
    },
  });
}

export function useConfirmRegistration() {
  const confirmRegistration = ({ token, agreeToTerms }) => api.post('/v2/user/confirm', {
    ...(token && { token }),
    agreeToTerms,
  });

  return useMutation(confirmRegistration, {
    onSuccess: (data) => {
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('userId', data['@iri'].replace('/v2/users/', ''));

      if (data.connected_accounts?.length) {
        const connectedAccounts = data.connected_accounts.map((connectedAccount) => ({
          accountId: connectedAccount.accountId,
          authToken: connectedAccount.token,
          refreshToken: connectedAccount.refresh_token,
        }));

        const lastAccountId = +localStorage.getItem('lastAccountId');

        if (!connectedAccounts.some((connectedAccount) => connectedAccount.accountId === lastAccountId)) {
          localStorage.removeItem('lastAccountId');
        }

        localStorage.setItem('connectedAccounts', JSON.stringify(connectedAccounts));
      }

      queryClient.setQueryData(['isTermsNotAccepted'], false);
    },
  });
}

export function useSendResetPasswordEmail() {
  const sendResetPasswordEmail = (email) => api.post('/v2/user/reset-password', { email });

  return useMutation(sendResetPasswordEmail, {
    onSuccess: (data) => {
      setSecondsToWait(data.secondsToWait, 'resetSecondsToWait');
    },
  });
}

export function useResetPassword() {
  const resetPassword = ({ token, password }) => api.post('/v2/user/reset-password-action', {
    token,
    plainPassword: password,
  });

  return useMutation(resetPassword);
}

export function useChangePassword() {
  const changePassword = ({ currentPassword, newPassword, confirmNewPassword }) => api.post('/v2/user/change-password', {
    currentPassword,
    plainPassword: newPassword,
    repeatedPlainPassword: confirmNewPassword,
  });

  return useMutation(changePassword);
}

export function useUpdateCurrentUser() {
  const updateCurrentUser = ({ firstName, lastName, countryCode }) => {
    const currentUser = queryClient.getQueryData(['currentUser']);

    return api.patch(`/v2/users/${currentUser.id}`, { firstName, lastName, countryCode });
  };

  return useMutation(updateCurrentUser, {
    onSuccess: (data) => {
      queryClient.setQueryData(['currentUser'], data);
    },
  });
}

export function useGetCurrentUserAvatar(url, options) {
  const getCurrentUserAvatar = () => (url ? api.get(url, {}, {
    headers: {
      'Content-Type': 'image/png',
    },
  }) : null);

  return useQuery(['currentUser.avatar', url], getCurrentUserAvatar, {
    ...options,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}

export function useCreateCurrentUserAvatar() {
  const createCurrentUserAvatar = ({ image }) => api.post('/v2/user-avatars', { image });

  return useMutation(createCurrentUserAvatar, {
    onSuccess: () => {
      queryClient.invalidateQueries('currentUser');
    },
  });
}

export function useUpdateCurrentUserAvatar() {
  const updateCurrentUserAvatar = ({ id, image }) => api.patch(`/v2/user-avatars/${id}`, { image });

  return useMutation(updateCurrentUserAvatar, {
    onSuccess: () => {
      queryClient.invalidateQueries('currentUser');
    },
  });
}

export function useDeleteCurrentUserAvatar() {
  const deleteCurrentUserAvatar = (id) => api.delete(`/v2/user-avatars/${id}`);

  return useMutation(deleteCurrentUserAvatar, {
    onSuccess: () => {
      queryClient.invalidateQueries('currentUser');
    },
  });
}

export function useAddToAccount() {
  let accountId = null;
  const activeAccountId = +sessionStorage.getItem('activeAccountId');

  if (activeAccountId) {
    accountId = activeAccountId;
  } else {
    const currentUser = queryClient.getQueryData(['currentUser']);

    accountId = +currentUser.account.replace('/v2/accounts/', '');
  }

  const addToAccount = ({
    email, firstName, lastName, role,
  }) => api.post(`/v2/user/add-to-account/${accountId}`, {
    email, firstName, lastName, role,
  });

  return useMutation(addToAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('account.usersList');
    },
  });
}

export function useConnectedAccounts() {
  const userId = localStorage.getItem('userId');

  const getConnectedAccounts = () => api.get(`/v2/users/${userId}/connected-accounts`)
    .then((data) => data['hydra:member']);

  return useQuery(['currentUser.accountsList', userId], getConnectedAccounts, {
    refetchOnMount: false,
    enabled: !!userId,
    onSuccess: (accounts) => {
      if (accounts?.length) {
        const connectedAccounts = accounts.map((connectedAccount) => ({
          accountId: connectedAccount.account.id,
          authToken: connectedAccount.token,
          refreshToken: connectedAccount.refreshToken,
        }));

        const lastAccountId = +localStorage.getItem('lastAccountId');

        if (!connectedAccounts.some((connectedAccount) => connectedAccount.accountId === lastAccountId)) {
          localStorage.removeItem('lastAccountId');
        }

        localStorage.setItem('connectedAccounts', JSON.stringify(connectedAccounts));
      }
    },
  });
}

export function useUpdateConnectedUser() {
  const updateConnectedUser = ({
    email, firstName, lastName, role,
  }) => api.patch(`/v2/user/update-connected/${email}`, {
    firstName, lastName, role,
  });

  return useMutation(updateConnectedUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('account.usersList');
    },
  });
}

export function useRemoveConnectedUser() {
  const removeConnectedUser = (email) => api.delete(`/v2/user/remove-connected/${email}`);

  return useMutation(removeConnectedUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('account.usersList');
    },
  });
}

export function useCreateAPIKey() {
  const createAPIKey = (name) => api.post('/v2/oauth-applications', { name });

  return useMutation(createAPIKey, {
    onSuccess: () => {
      queryClient.invalidateQueries('apiKeys.list');
    },
  });
}

export function useAPIKeysList() {
  const itemsPerPage = 50;

  const getAPIKeysList = () => api.get('/v2/oauth-applications', {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (pageNumber) => api.get('/v2/oauth-applications', {
          page: pageNumber,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['apiKeys.list'], getAPIKeysList);
}

export function useGetAPIKey() {
  const getAPIKey = (identifier) => api.get(`/v2/oauth-applications/${identifier}`);

  return useMutation(getAPIKey);
}

export function useDeleteAPIKey() {
  const deleteAPIKey = (identifier) => api.delete(`/v2/oauth-applications/${identifier}`);

  return useMutation(deleteAPIKey, {
    onSuccess: () => {
      queryClient.invalidateQueries('apiKeys.list');
    },
  });
}

export function useUpdateAPIKey() {
  const updateAPIKey = ({ identifier, name }) => api.patch(`/v2/oauth-applications/${identifier}`, { name });

  return useMutation(updateAPIKey, {
    onSuccess: () => {
      queryClient.invalidateQueries('apiKeys.list');
    },
  });
}
