import React, { Fragment, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';

import { accountApi } from 'resources/account';
import { subscriptionApi } from 'resources/subscription';
import { userApi } from 'resources/user';

import { StackIcon } from 'assets/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEye } from '@fortawesome/free-regular-svg-icons';

import Button from 'components/Button';

import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import { checkSelectItems } from 'pages/IPMonitor/components/AddOrEditIPMonitorModal';

import { USER_ROLES } from 'resources/user/user.constants';

import './styles.scss';

const SubscriptionAndUsage = () => {
  const navigate = useNavigate();

  const {
    data: subscriptionTemplates,
    isLoading: isSubscriptionTemplatesLoading,
  } = subscriptionApi.useSubscriptionTemplates();
  const {
    data: account,
    isLoading: isAccountLoading,
  } = accountApi.useAccount();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
  } = subscriptionApi.useSubscription(account?.subscription
    ? +account.subscription.replace('/v2/subscriptions/', '')
    : null);
  const { data: connectedAccounts } = userApi.useConnectedAccounts();

  const activeAccountId = +sessionStorage.getItem('activeAccountId');
  const activeAccount = activeAccountId && connectedAccounts
    ?.find((connectedAccount) => connectedAccount.account.id === activeAccountId);

  const isOwner = !activeAccountId;
  const isAdmin = activeAccount?.roles?.includes(USER_ROLES.ADMIN);

  const isLoading = isAccountLoading || isSubscriptionLoading || isSubscriptionTemplatesLoading || !subscription;

  const isMonthlySubscription = !!subscription?.monthlyPrice && !subscription?.annualPrice;
  const price = subscription?.monthlyPrice || subscription?.annualPrice;

  const subscriptionTemplate = subscriptionTemplates
    ?.find((pricingPlan) => pricingPlan['@id'] === subscription?.subscriptionTemplate
    && (pricingPlan.free
      || (subscription?.monthlyPrice && isMonthlySubscription)
    || (subscription?.annualPrice && !isMonthlySubscription)));

  const onNavigateToPricingPlans = useCallback(() => {
    navigate(routes.pricingPlans.path);
  }, [navigate]);

  return (
    <AuthorizedLayout>
      <div className="subscription-and-usage-wrapper">
        <p className="title">Subscription & Usage</p>

        {isLoading && <CircularProgress style={{ margin: 'auto' }} />}

        {!isLoading && (
          <div className="subscription-and-usage-content">
            <div className="plan-header">
              <div className="plan-info">
                <div className="icon-wrapper">
                  <StackIcon color="#17EDC3" />
                </div>

                <div className="plan-name-wrapper">
                  <p>{subscriptionTemplate?.title}</p>

                  <div>
                    <p>{subscription?.free
                      ? 'Free'
                      : `$${(price / 100).toFixed(2)}/${isMonthlySubscription ? 'month' : 'year'}`}
                    </p>
                    {!subscription?.free && <p>|</p>}
                    {!subscription?.free && <p>Renews on {moment(subscription?.nextPaymentDate).format('MMM DD, YYYY')}</p>}
                  </div>
                </div>
              </div>

              {(isOwner || isAdmin) && (
              <div className="plan-header-buttons">
                {!subscription?.free && (
                <Button
                  className="manage-plan-button"
                  onClick={onNavigateToPricingPlans}
                  color="secondaryButton"
                >
                  Change plan
                </Button>
                )}

                {subscription?.free && (
                <Button
                  className="upgrade-button"
                  onClick={onNavigateToPricingPlans}
                >
                  Upgrade
                </Button>
                )}
              </div>
              )}
            </div>

            <div className="plan-body">
              {subscription?.featureGroups.map((featureGroup, index) => (
                <Fragment key={featureGroup['@id']}>
                  <div className="plan-section">
                    <div className="plan-section-name">
                      <div className="icon-wrapper">
                        <FontAwesomeIcon icon={faEye} />
                      </div>

                      <div className="plan-section-name-info">
                        <p className="plan-section-name-title">{featureGroup.name}</p>

                        <p>{featureGroup.description}</p>
                      </div>
                    </div>

                    <div className="vertical-line" />

                    <div className="progress-bars-wrapper">
                      {featureGroup.items.map((item, itemIndex) => {
                        const isFrequency = item.type === 'Frequency';
                        const isExists = item.quantityInit > 0;
                        const quantityUsed = item.quantityInit - item.quantityLeft;
                        const usedPart = quantityUsed / item.quantityInit;
                        const percentUsed = (usedPart * 100).toFixed(0);
                        const progressWidth = usedPart * 275;

                        return (
                          <Fragment key={item['@id']}>
                            <div className="progress-bar-wrapper">
                              <div className="progress-bar-header">
                                <Tooltip title={item.name} placement="top">
                                  <p {...(isFrequency && { style: { maxWidth: 'unset' } })}>{item.name}</p>
                                </Tooltip>

                                {!isFrequency && isExists && (
                                <div>
                                  <p>Used</p>
                                  <p>
                                    {quantityUsed}
                                    {' | '}
                                    {percentUsed}%
                                  </p>
                                </div>
                                )}
                              </div>

                              {!isFrequency && (
                              <div className={cn('progress-bar', !isExists && 'progress-bar-disabled')}>
                                <div style={{ width: progressWidth }} />
                              </div>
                              )}

                              {isFrequency && (
                              <div className="frequancy-wrapper">
                                {item.frequency.map((seconds) => (
                                  <p key={seconds}>- {checkSelectItems
                                    .find((selectItem) => selectItem.value === seconds)
                                    .label}
                                  </p>
                                ))}
                              </div>
                              )}
                            </div>

                            {(itemIndex + 1) % 2 !== 0 && <div className="vertical-line" />}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>

                  {index < (subscription.featureGroups.length - 1) && <div className="line" />}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default SubscriptionAndUsage;
