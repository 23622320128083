import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { routes } from 'routes';

import { ErrorOutlineIcon, QuestionMarkIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';
import { accountApi } from 'resources/account';
import { subscriptionApi } from 'resources/subscription';
import { connectivityApi } from 'resources/connectivity';

import { Tooltip, IconButton, CircularProgress, Link, Switch, FormControlLabel } from '@mui/material';

import Modal from 'components/Modal';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import Select from 'components/Select';
import Button from 'components/Button';
import Popover from 'components/Popover';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import {
  checkRadioItems,
  checkSelectItems,
  notificationsRadioItems,
  notificationFrequencySelectItems,
} from 'pages/IPMonitor/components/AddOrEditIPMonitorModal';

import './styles.scss';

const AddOrEditEmailInfrastructureMonitorModal = ({
  onClose,
  initialData,
  onMonitorAdded,
  onMonitorUpdated,
  emailAccountId,
}) => {
  const navigate = useNavigate();

  const [emailAccount, setEmailAccount] = useState(emailAccountId || '');
  const [checkSelectValue, setCheckSelectValue] = useState(initialData?.scheduleSeconds);
  const [notificationsSelectValue, setNotificationsSelectValue] = useState(null);
  const [notificationFrequencySelectValue, setNotificationFrequencySelectValue] = useState(
    notificationFrequencySelectItems[0].value,
  );
  const [checkRadioValue, setCheckRadioValue] = useState(
    checkRadioItems[0].value,
  );
  const [notificationsRadioValue, setNotificationsRadioValue] = useState('saved_presets');
  const [customNotificationSettigs, setCustomNotificationSettings] = useState({
    email: {
      enabled: false,
      value: '',
      error: null,
    },
    sms: {
      enabled: false,
      value: '',
      error: null,
    },
    slack: {
      enabled: false,
      value: '',
      error: null,
    },
    pagerDuty: {
      enabled: false,
      value: '',
      error: null,
    },
  });
  const [isSaveAsNewPreset, setIsSaveAsNewPreset] = useState(false);
  const [customPresetName, setCustomPresetName] = useState('');
  const [errors, setErrors] = useState({
    emailAccount: null,
    presetName: null,
    customPresetName: null,
  });
  const [generalError, setGeneralError] = useState(null);
  const [isDefaultCustomNotificationSettingsSetted, setIsDefaultCustomNotificationSettingsSetted] = useState(false);

  const [questionIconRef, setQuestionIconRef] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const {
    data: monitorNotificationList,
    isLoading: isMonitorNotificationListLoaing,
  } = monitorApi.useMonitorNotificationList();
  const {
    mutate: createMonitorNotification,
    isLoading: isCreateMonitorNotificaitonLoading,
  } = monitorApi.useCreateMonitorNotification();
  const {
    mutate: updateMonitor,
    isLoading: isUpdateMonitorLoading,
  } = monitorApi.useUpdateMonitor();
  const {
    mutate: createConnectivityMonitor,
    isLoading: isCreateConnectivityMonitorLoading,
  } = connectivityApi.useCreateConnectivityMonitor();
  const {
    data: account,
    isLoading: isAccountLoading,
  } = accountApi.useAccount();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
  } = subscriptionApi.useSubscription(account?.subscription
    ? +account.subscription.replace('/v2/subscriptions/', '')
    : null);
  const {
    data: monitorNotification,
    isLoading: isMonitorNotificationLoading,
  } = monitorApi.useGetMonitorNotification(initialData?.notificationId);
  const {
    mutate: updateMonitorNotification,
    isLoading: isUpdateMonitorNotificaitonLoading,
  } = monitorApi.useUpdateMonitorNotification();
  const {
    mutate: deleteMonitorNotification,
    isLoading: isDeleteMonitorNotificaitonLoading,
  } = monitorApi.useDeleteMonitorNotification();
  const { data: emailsData, isLoading: isEmailsLoading } = connectivityApi.useConnectivityEmailAccounts({
    pagination: false,
    accountStatus: 'active',
  });

  const isLoading = isMonitorNotificationListLoaing || isCreateMonitorNotificaitonLoading
  || isAccountLoading || isSubscriptionLoading || isDeleteMonitorNotificaitonLoading
  || isMonitorNotificationLoading || isUpdateMonitorNotificaitonLoading;

  const emailInfrastructureMonitorsFrequency = subscription?.featureGroups
    .find((featureGroup) => featureGroup.name === 'Email Infrastructure MX Right')
    .items.find((item) => item?.feature === 'infrastructure_monitors_check_frequency')
    ?.frequency;

  const emails = useMemo(() => emailsData?.items || [], [emailsData]);

  const emailItems = useMemo(() => emails.map((email) => ({
    value: email.id,
    label: email.fromEmail,
    disabled: !!email.monitor,
  })), [emails]);

  const filteredCheckSelectItems = useMemo(() => (emailInfrastructureMonitorsFrequency
    ? checkSelectItems.map((checkSelectItem) => {
      const isDisabled = !emailInfrastructureMonitorsFrequency.includes(checkSelectItem.value);

      return ({
        ...checkSelectItem,
        selectedLabel: checkSelectItem.label,
        label: (
          <div className={cn('check-select-label', isDisabled && 'check-select-label-disabled')}>
            <p>{checkSelectItem.label}</p>

            {isDisabled && (
            <Button
              variant="text"
              className="upgrade-plan-button"
              onClick={() => navigate(routes.pricingPlans.path)}
            >
              Upgrade
            </Button>
            )}
          </div>
        ),
        tooltipText: isDisabled ? 'To use this value you need to update your plan.' : null,
        tooltipPlacement: 'left-start',
        isDisabled,
      });
    })
    : []), [emailInfrastructureMonitorsFrequency, navigate]);
  const isNoActiveEmailAccounts = !emailItems.length && !isEmailsLoading;
  const defaultNotificationPresetId = Number(account?.defaultNotificationPreset.replace('/v2/monitor-notifications/', ''));

  useEffect(() => {
    // Saved preset
    if (!notificationsSelectValue && initialData?.notificationId && monitorNotification?.shared) {
      setNotificationsSelectValue(initialData?.notificationId);
      setNotificationsRadioValue('saved_presets');
    }
  }, [monitorNotificationList, notificationsSelectValue,
    initialData?.notificationId, monitorNotification?.shared]);

  useEffect(() => {
    if (initialData && !emailAccount && emailItems.length) {
      const selectedEmailAccount = emailItems.find((item) => item.label === initialData.name);

      if (selectedEmailAccount) {
        setEmailAccount(selectedEmailAccount.value);
      }
    }
  }, [initialData, emailAccount, emailItems]);

  useEffect(() => {
    if (!notificationsSelectValue && notificationsRadioValue === 'saved_presets' && monitorNotificationList?.length) {
      setNotificationsSelectValue(defaultNotificationPresetId);
    }
  }, [defaultNotificationPresetId, monitorNotificationList, notificationsRadioValue, notificationsSelectValue]);

  useEffect(() => {
    if (!checkSelectValue && filteredCheckSelectItems.length) {
      const firstAvailableCheckSelectItem = filteredCheckSelectItems.find((item) => !item.isDisabled);

      setCheckSelectValue(firstAvailableCheckSelectItem.value);
    }
  }, [checkSelectValue, filteredCheckSelectItems]);

  useEffect(() => {
    if (!isDefaultCustomNotificationSettingsSetted && monitorNotification && !monitorNotification.shared) {
      const newData = {};

      if (monitorNotification.target.emails) {
        newData.email = {
          enabled: true,
          value: monitorNotification.target.emails.join(', '),
          error: null,
        };
      }

      if (monitorNotification.target.pagerDuty) {
        newData.pagerDuty = {
          enabled: true,
          value: monitorNotification.target.pagerDuty,
          error: null,
        };
      }

      setCustomNotificationSettings((oldData) => ({
        ...oldData,
        ...newData,
      }));

      setNotificationFrequencySelectValue(monitorNotification.schedule);

      setIsDefaultCustomNotificationSettingsSetted(true);
    }
  }, [isDefaultCustomNotificationSettingsSetted, monitorNotification]);

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const onCloseClick = useCallback((_, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  }, [onClose]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!emailAccount) {
      newErrors.emailAccount = 'This field is required';
    }

    if (isSaveAsNewPreset && !customPresetName) {
      newErrors.customPresetName = 'This field is required';
    }

    // check custom notification settings
    const newCustomNotificationSettingsErrors = {};

    if (notificationsRadioValue === 'custom_settings') {
      const { email, pagerDuty } = customNotificationSettigs;

      if (email.enabled) {
        if (!email.value) {
          newCustomNotificationSettingsErrors.email = 'This field is required';
        }

        if (email.value && !email.value.split(',').every((item) => validateEmail(item.trim()))) {
          newCustomNotificationSettingsErrors.email = 'Incorrect email format';
        }
      }

      if (pagerDuty.enabled) {
        if (!pagerDuty.value) {
          newCustomNotificationSettingsErrors.pagerDuty = 'This field is required';
        }

        if (pagerDuty.value && !validateEmail(pagerDuty.value)) {
          newCustomNotificationSettingsErrors.pagerDuty = 'Incorrect email format';
        }
      }

      setCustomNotificationSettings((old) => ({
        ...old,
        ...(newCustomNotificationSettingsErrors.email && {
          email: { ...old.email, error: newCustomNotificationSettingsErrors.email },
        }),
        ...(newCustomNotificationSettingsErrors.pagerDuty && {
          pagerDuty: { ...old.pagerDuty, error: newCustomNotificationSettingsErrors.pagerDuty },
        }),
      }));
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length && !Object.keys(newCustomNotificationSettingsErrors).length;
  }, [emailAccount, notificationsRadioValue, customNotificationSettigs, customPresetName, isSaveAsNewPreset]);

  const addOrUpdateMonitor = useCallback((notificationId) => {
    if (initialData) {
      updateMonitor({
        id: initialData.id,
        scheduleSeconds: checkSelectValue,
        notificationId: notificationId || notificationsSelectValue,
      }, {
        onSuccess: (data) => {
          uiNotificationService.showSuccess('Monitor has been updated');
          setGeneralError(null);

          if (onMonitorUpdated) {
            onMonitorUpdated({
              id: data.id,
              name: data.title,
              scheduleSeconds: data.scheduleSeconds,
              notificationId: +data.notification.replace('/v2/monitor-notifications/', ''),
            });
          }

          onClose();
        },
        onError: (error) => {
          setGeneralError(error.data['hydra:description']);
        },
      });

      return;
    }

    createConnectivityMonitor({
      emailAccountId: emailAccount,
      title: emailItems.find((item) => item.value === emailAccount)?.label,
      scheduleSeconds: checkSelectValue,
      notificationId: notificationId || notificationsSelectValue,
      notificationEnabled: true,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email account monitor has been created');
        onMonitorAdded();

        onClose();
      },
      onError: (error) => {
        setGeneralError(error.data['hydra:description']);
      },
    });
  }, [notificationsSelectValue, checkSelectValue, emailAccount,
    updateMonitor, initialData, onMonitorAdded, onMonitorUpdated,
    onClose, createConnectivityMonitor, emailItems]);

  const onCreateOrUpdateMonitor = useCallback(() => {
    // save custom notification settings
    if (notificationsRadioValue === 'custom_settings') {
      const requestData = {
        ...isSaveAsNewPreset && { presetName: customPresetName },
        ...customNotificationSettigs.email.enabled && {
          emails: customNotificationSettigs.email.value.split(',').map((email) => email.trim()),
        },
        ...customNotificationSettigs.sms.enabled && { sms: customNotificationSettigs.sms.value },
        ...customNotificationSettigs.slack.enabled && { slack: customNotificationSettigs.slack.value },
        ...customNotificationSettigs.pagerDuty.enabled && { pagerDuty: customNotificationSettigs.pagerDuty.value },
        schedule: notificationFrequencySelectValue,
        shared: isSaveAsNewPreset,
      };
      const onSuccess = (data) => {
        addOrUpdateMonitor(data.id);
      };
      const onError = (error) => {
        setGeneralError(error.data['hydra:description']);
      };

      if (!initialData || monitorNotification?.shared) {
        createMonitorNotification(requestData, {
          onSuccess,
          onError,
        });

        return;
      }

      updateMonitorNotification({
        id: initialData.notificationId,
        ...requestData,
      }, {
        onSuccess,
        onError,
      });

      return;
    }

    // delete custom notification preset if switch to saved preset
    if (!monitorNotification?.shared && initialData?.notificationId) {
      deleteMonitorNotification(initialData.notificationId);
    }

    addOrUpdateMonitor();
  }, [addOrUpdateMonitor, customNotificationSettigs, initialData,
    createMonitorNotification, notificationFrequencySelectValue, notificationsRadioValue,
    updateMonitorNotification, monitorNotification?.shared, deleteMonitorNotification,
    isSaveAsNewPreset, customPresetName]);

  const onNextClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    onCreateOrUpdateMonitor();
  }, [validateForm, onCreateOrUpdateMonitor]);

  return (
    <Modal
      open
      onClose={onCloseClick}
      title={`${initialData ? 'Edit' : 'Add'} Email Infrastructure Monitor`}
      disableEscapeKeyDown
    >
      {isLoading && <CircularProgress className="add-ip-monitor-loader" />}

      {!isLoading && (
        <div className="add-email-infrastructure-monitor-modal-wrapper">
          {generalError && (
          <div className="general-error-wrapper">
            <ErrorOutlineIcon />
            <p>{generalError}</p>
          </div>
          )}

          <div className="add-ip-monitor-form">
            <Tooltip
              title={isNoActiveEmailAccounts ? 'You need to have an active email account to send a test email' : ''}
              placement="top"
            >
              <Select
                value={emailAccount}
                placeholder="Select Email Account"
                onChange={(value) => onChangeInput({
                  value,
                  setter: setEmailAccount,
                  errorField: 'emailAccount',
                })}
                error={!!errors.emailAccount}
                helperText={errors.emailAccount}
                items={emailItems}
                type="filled"
                disabled={isNoActiveEmailAccounts || !!initialData || emailAccountId}
                renderValue={(renderValue) => (
                  <p>
                    {emailItems.find((selectItem) => selectItem.value === renderValue)?.label}
                  </p>
                )}
                endAdornment={(
                  <IconButton onClick={() => setIsPopoverVisible(true)} sx={{ marginRight: '10px' }}>
                    <QuestionMarkIcon
                      ref={setQuestionIconRef}
                    />
                  </IconButton>
                )}
              />
            </Tooltip>
            {isPopoverVisible && (
            <Popover
              forRef={questionIconRef}
              title="Email Infrastructure Monitor"
              description="Select an email account to monitor."
              onClose={() => setIsPopoverVisible(false)}
              placement="top-start"
            />
            )}
          </div>

          <div className="line" />

          <div className="check-frequency">
            <p className="section-title">Check Frequency</p>

            <div className="check-frequency-container">
              <RadioGroup
                items={checkRadioItems}
                value={checkRadioValue}
                onChange={setCheckRadioValue}
              />

              {filteredCheckSelectItems && checkSelectValue && (
              <Select
                className="check-select"
                value={checkSelectValue}
                onChange={setCheckSelectValue}
                items={filteredCheckSelectItems}
                type="filled"
                renderValue={(renderValue) => (
                  <div className="select-value">
                    <p>Check Every</p>
                    <p>
                      {filteredCheckSelectItems.find((selectItem) => selectItem.value === renderValue)?.selectedLabel}
                    </p>
                  </div>
                )}
              />
              )}
            </div>
          </div>

          <div className="line" />

          <div className="notifications">
            <p className="section-title">Notifications</p>

            <div>
              <div className="check-frequency-container" key={monitorNotificationList.length.toString()}>
                <RadioGroup
                  items={notificationsRadioItems.map((radioItem) => {
                    if (radioItem.value === 'saved_presets' && !monitorNotificationList.length) {
                      radioItem.disabled = true;
                    }

                    return radioItem;
                  })}
                  value={notificationsRadioValue}
                  onChange={setNotificationsRadioValue}
                />

                {notificationsRadioValue === 'saved_presets' && (
                <Select
                  className="check-select"
                  value={notificationsSelectValue}
                  onChange={setNotificationsSelectValue}
                  items={monitorNotificationList.map((notification) => ({
                    value: notification.id,
                    label: notification.presetName,
                  }))}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>Select Preset</p>
                      <p>
                        {monitorNotificationList
                          .find((selectItem) => selectItem.id === renderValue)?.presetName}
                      </p>
                    </div>
                  )}
                />
                )}
              </div>

              {notificationsRadioValue === 'custom_settings' && (
              <div className="notification-custom-settings">
                <div>
                  <FormControlLabel
                    className={cn('switch-container', {
                      'switch-container-checked': customNotificationSettigs.email.enabled,
                    })}
                    control={(
                      <Switch
                        checked={customNotificationSettigs.email.enabled}
                        onChange={(e) => setCustomNotificationSettings((old) => ({
                          ...old,
                          email: {
                            ...old.email,
                            enabled: e.target.checked,
                          },
                        }))}
                      />
                  )}
                    label="Email"
                  />

                  {customNotificationSettigs.email.enabled && (
                  <div>
                    <Input
                      multiline
                      rows={3}
                      className="emails-textarea"
                      value={customNotificationSettigs.email.value}
                      onChange={(value) => setCustomNotificationSettings((old) => ({
                        ...old,
                        email: {
                          ...old.email,
                          value,
                          error: null,
                        },
                      }))}
                      error={!!customNotificationSettigs.email.error}
                    />
                    <p className={cn('helper-text', { 'helper-text-error': !!customNotificationSettigs.email.error })}>
                      {customNotificationSettigs.email.error || 'Separate by a comma'}
                    </p>
                  </div>
                  )}
                </div>

                <div>
                  <FormControlLabel
                    className={cn('switch-container', { 'switch-container-checked': customNotificationSettigs.sms.enabled })}
                    control={(
                      <Switch
                        checked={customNotificationSettigs.sms.enabled}
                        onChange={(e) => setCustomNotificationSettings((old) => ({
                          ...old,
                          sms: {
                            ...old.sms,
                            enabled: e.target.checked,
                          },
                        }))}
                      />
                  )}
                    label={(
                      <div className="switch-label">
                        <p>SMS</p>

                        <div className="comming-soon-label">
                          <p>Comming soon...</p>
                        </div>
                      </div>
                  )}
                    disabled
                  />
                </div>

                <div>
                  <FormControlLabel
                    className={cn('switch-container', {
                      'switch-container-checked': customNotificationSettigs.slack.enabled,
                    })}
                    control={(
                      <Switch
                        checked={customNotificationSettigs.slack.enabled}
                        onChange={(e) => setCustomNotificationSettings((old) => ({
                          ...old,
                          slack: {
                            ...old.slack,
                            enabled: e.target.checked,
                          },
                        }))}
                      />
                  )}
                    label={(
                      <div className="switch-label">
                        <p>Slack</p>

                        <div className="comming-soon-label">
                          <p>Comming soon...</p>
                        </div>
                      </div>
                  )}
                    disabled
                  />
                </div>

                <div>
                  <FormControlLabel
                    className={cn('switch-container', {
                      'switch-container-checked': customNotificationSettigs.pagerDuty.enabled,
                    })}
                    control={(
                      <Switch
                        checked={customNotificationSettigs.pagerDuty.enabled}
                        onChange={(e) => setCustomNotificationSettings((old) => ({
                          ...old,
                          pagerDuty: {
                            ...old.pagerDuty,
                            enabled: e.target.checked,
                          },
                        }))}
                      />
                  )}
                    label="PagerDuty"
                  />

                  {customNotificationSettigs.pagerDuty.enabled && (
                  <div>
                    <Input
                      label="PagerDuty Email"
                      value={customNotificationSettigs.pagerDuty.value}
                      onChange={(value) => setCustomNotificationSettings((old) => ({
                        ...old,
                        pagerDuty: {
                          ...old.pagerDuty,
                          value,
                          error: null,
                        },
                      }))}
                      error={!!customNotificationSettigs.pagerDuty.error}
                    />
                    <p className={cn('helper-text', { 'helper-text-error': !!customNotificationSettigs.pagerDuty.error })}>
                      {customNotificationSettigs.pagerDuty.error
                      || (
                      <>
                        Check out
                        {' '}
                        <Link
                          href="https://support.pagerduty.com/docs/email-integration-guide"
                          target="_blank"
                          underline="hover"
                        >
                          Email Integration Guide
                        </Link>
                        {' '}
                        to setup a PagerDuty email
                      </>
                      )}
                    </p>
                  </div>
                  )}
                </div>

                <Select
                  value={notificationFrequencySelectValue}
                  onChange={setNotificationFrequencySelectValue}
                  items={notificationFrequencySelectItems}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>Notification Frequency</p>
                      <p>
                        {notificationFrequencySelectItems
                          .find((selectItem) => selectItem.value === renderValue).label}
                      </p>
                    </div>
                  )}
                />

                <div>
                  <FormControlLabel
                    className={cn('switch-container', {
                      'switch-container-checked': isSaveAsNewPreset,
                    })}
                    control={(
                      <Switch
                        checked={isSaveAsNewPreset}
                        onChange={(e) => setIsSaveAsNewPreset(e.target.checked)}
                      />
                  )}
                    label="Save as new preset"
                  />

                  {isSaveAsNewPreset && (
                  <div>
                    <Input
                      className="custom-preset-input"
                      placeholder="Preset Name"
                      value={customPresetName}
                      onChange={(value) => onChangeInput({
                        value,
                        setter: setCustomPresetName,
                        errorField: 'customPresetName',
                      })}
                      error={!!errors.customPresetName}
                      helperText={errors.customPresetName}
                    />
                  </div>
                  )}
                </div>
              </div>
              )}

              <div className="confirm-buttons">
                <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
                <Button
                  onClick={onNextClick}
                  loading={isUpdateMonitorLoading || isCreateConnectivityMonitorLoading}
                >
                  {initialData ? 'Update' : 'Next'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

AddOrEditEmailInfrastructureMonitorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onMonitorAdded: PropTypes.func,
  onMonitorUpdated: PropTypes.func,
  initialData: PropTypes.shape(),

  emailAccountId: PropTypes.string,
};

AddOrEditEmailInfrastructureMonitorModal.defaultProps = {
  onMonitorAdded: null,
  onMonitorUpdated: null,
  initialData: null,

  emailAccountId: false,
};

export default React.memo(AddOrEditEmailInfrastructureMonitorModal);
