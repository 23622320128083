import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import {
  DotsIcon, PencilIcon, TrashIcon,
  CardioLineIcon, EmailInfrastureIcon,
  HistoryIcon,
} from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import DeleteModal from 'components/DeleteModal';
import AddHocScanModal from 'components/AddHocScanModal';
import ReportHistoryModal from 'components/ReportHistoryModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import AddOrEditEmailInfrastructureMonitorModal from '../AddOrEditEmailInfrastructureMonitorModal';

import './styles.scss';

const PER_PAGE = 10;

const IPMonitorMenu = ({
  id,
  name,
  enabled,
  scheduleSeconds,
  notificationId,
  generatedEmail,
  checkId,
  onDeleted,
  onUpdated,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const [page, setPage] = useState(1);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isGeneratedModalVisible, setIsGeneratedModalVisible] = useState(false);
  const [isReportHistoryModalVisible, setIsReportHistoryModalVisible] = useState(false);

  const {
    mutate: updateMonitor,
    isLoading: isUpdateMonitorLoading,
  } = monitorApi.useUpdateMonitor();
  const {
    mutate: deleteMonitor,
    isLoading: isDeleteMonitorLoading,
  } = monitorApi.useDeleteMonitor();
  const { data: monitorCheckHistoryList } = monitorApi.useMonitorCheckHistoryList({
    checkId,
    page,
    perPage: PER_PAGE,
  });

  const selectItems = monitorCheckHistoryList ? monitorCheckHistoryList.items.map((item) => ({
    value: item.id,
    label: moment(item.checkAt).format('MMM DD, YYYY [at] h:mmA'),
    ...item,
  })) : [];

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onMonitorStatusToggle = useCallback(() => {
    updateMonitor({
      id,
      status: enabled ? 'disabled' : 'active',
    }, {
      onSuccess: () => {
        if (onUpdated) {
          onUpdated({
            id,
            enabled: !enabled,
          });
        }

        closeMenu();
      },
    });
  }, [id, onUpdated, enabled, updateMonitor, closeMenu]);

  const onDelete = useCallback(() => {
    deleteMonitor(id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Monitor has been deleted successfully!');

        setIsDeleteModalVisible(false);
        closeMenu();

        onDeleted();
      },
    });
  }, [deleteMonitor, id, onDeleted, closeMenu]);

  const onMonitorUpdated = useCallback((data) => {
    setIsEditModalVisible(false);
    closeMenu();

    if (onUpdated) {
      onUpdated(data);
    }
  }, [onUpdated, closeMenu]);

  const onCloseReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(false);

    closeMenu();
  }, [closeMenu]);

  return (
    <>
      <DeleteModal
        title="Are you sure you want to delete this monitor and all its history?"
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={onDelete}
        isDeleteLoading={isDeleteMonitorLoading}
      />
      <AddHocScanModal
        open={isGeneratedModalVisible}
        onClose={() => { setIsGeneratedModalVisible(false); closeMenu(); }}
        type="copyEmail"
        generatedEmail={generatedEmail}
      />
      {isEditModalVisible && (
        <AddOrEditEmailInfrastructureMonitorModal
          onClose={() => { setIsEditModalVisible(false); closeMenu(); }}
          initialData={{ id, name, scheduleSeconds, notificationId }}
          onMonitorUpdated={onMonitorUpdated}
        />
      )}
      {!!selectItems.length && isReportHistoryModalVisible && (
      <ReportHistoryModal
        open={isReportHistoryModalVisible}
        onClose={onCloseReportHistoryModal}
        forName="Monitor"
        items={selectItems}
        page={page}
        perPage={PER_PAGE}
        totalItems={monitorCheckHistoryList?.totalItems}
        onPageChanged={(newPage) => setPage(newPage)}
        onItemClick={(item) => navigate(routes.monitorResult.path.replace(':checkId', `${checkId}#${item.id}`))}
      />
      )}

      <div className={cn('menu-wrapper', anchorEl && 'menu-wrapper-active')}>
        <IconButton onClick={openMenu}>
          <DotsIcon />
        </IconButton>

        <Menu
          open={!!anchorEl}
          onClose={closeMenu}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={() => setIsEditModalVisible(true)} className="menu-item">
            <PencilIcon />
            <p>Edit</p>
          </MenuItem>
          <MenuItem onClick={() => setIsDeleteModalVisible(true)} className="menu-item" disabled={isDeleteMonitorLoading}>
            <TrashIcon />
            <p>Delete</p>
          </MenuItem>
          <MenuItem onClick={() => setIsGeneratedModalVisible(true)} className="menu-item" style={{ fillOpacity: 0.56 }}>
            <EmailInfrastureIcon width={24} />
            <p>Monitor Address</p>
          </MenuItem>
          {checkId && (
          <MenuItem onClick={() => setIsReportHistoryModalVisible(true)} className="menu-item">
            <HistoryIcon />
            <p>Report History</p>
          </MenuItem>
          )}

          <div className="menu-line" />

          <MenuItem className="menu-item" disabled={isUpdateMonitorLoading}>
            <CardioLineIcon />
            <p>Status</p>

            <Switch
              checked={enabled}
              onChange={onMonitorStatusToggle}
              disabled={isUpdateMonitorLoading}
            />
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

IPMonitorMenu.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  scheduleSeconds: PropTypes.number.isRequired,
  notificationId: PropTypes.number.isRequired,
  generatedEmail: PropTypes.string.isRequired,
  checkId: PropTypes.number.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onUpdated: PropTypes.func,
};

IPMonitorMenu.defaultProps = {
  onUpdated: null,
};

export default React.memo(IPMonitorMenu);
