import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import Terms from 'pages/Terms';
import Results from 'pages/Results';
import ResultsFrame from 'pages/Results/Frame';
import IPMonitor from 'pages/IPMonitor';
import RealTimeMonitor from 'pages/RealTimeMonitor';
import EmailInfrastructure from 'pages/EmailInfrastructure';
import EmailConfirmation from 'pages/EmailConfirmation';
import Callback from 'pages/Callback';
import ResetPassword from 'pages/ResetPassword';
import SetNewPassword from 'pages/SetNewPassword';
import TemplateMXRisk from 'pages/TemplateMXRisk';
import TemplateMXRiskFolder from 'pages/TemplateMXRiskFolder';
import TemplateMXRiskAnalyze from 'pages/TemplateMXRisk/Analyze';
import TemplateMXRiskEdit from 'pages/TemplateMXRisk/Edit';
import TemplateMXRiskResult from 'pages/TemplateMXRisk/Result';
import Profile from 'pages/Profile';
import ChangePassword from 'pages/ChangePassword';
import Maintenance from 'pages/Maintenance';
import NotFound from 'pages/NotFound';
import NotificationPresets from 'pages/NotificationPresets';
import MonitorResults from 'pages/MonitorResults';
import EmailMXRisk from 'pages/EmailMXRisk';
import EmailMXRiskResult from 'pages/EmailMXRisk/Result';
import EmailGroupNameHistory from 'pages/EmailGroupNameHistory';
import APIKeys from 'pages/APIKeys';
import Billing from 'pages/Billing';
import SubscriptionAndUsage from 'pages/SubscriptionAndUsage';
import PricingPlans from 'pages/PricingPlans';
import Users from 'pages/Users';
import Dashboard from 'pages/Dashboard';
import EmailAccounts from 'pages/EmailAccounts';
import EmailAccountCallback from 'pages/EmailAccountCallback';
import DeliverabilityTest from 'pages/DeliverabilityTest';
import DeliverabilityTestDetails from 'pages/DeliverabilityTestDetails';
import DeliverabilityTestStartNew from 'pages/DeliverabilityTest/StartNew';

export const scopes = {
  authenticated: 'authenticated',
  notAuthenticated: 'not_authenticated',
  none: 'none',
};

const isHideSignUp = process.env.REACT_APP_USE_HIDE_SIGNUP === undefined ? false : +process.env.REACT_APP_USE_HIDE_SIGNUP;

export const routes = {
  signIn: {
    path: '/sign-in',
    scope: scopes.notAuthenticated,
    component: <SignIn />,
  },
  ...(!isHideSignUp && {
    signUp: {
      path: '/sign-up',
      scope: scopes.notAuthenticated,
      component: <SignUp />,
    },
  }),
  callback: {
    path: '/callback',
    scope: scopes.none,
    component: <Callback />,
  },
  emailConfirmation: {
    path: '/email-confirmation',
    scope: scopes.notAuthenticated,
    component: <EmailConfirmation />,
  },
  resetPassword: {
    path: '/reset-password',
    scope: scopes.notAuthenticated,
    component: <ResetPassword />,
  },
  setNewPassword: {
    path: '/set-new-password',
    scope: scopes.none,
    component: <SetNewPassword />,
  },
  terms: {
    path: '/terms',
    scope: scopes.notAuthenticated,
    component: <Terms />,
  },
  result: {
    path: '/result/:email',
    scope: scopes.none,
    component: <Results />,
  },
  resultFrame: {
    path: '/result/frame/:email',
    scope: scopes.none,
    component: <ResultsFrame />,
  },
  ipMonitor: {
    path: '/ip-monitor',
    scope: scopes.authenticated,
    component: <IPMonitor />,
  },
  realTimeMonitor: {
    path: '/real-time-monitor/:monitorId',
    scope: scopes.authenticated,
    component: <RealTimeMonitor />,
  },
  emailInfrastructure: {
    path: '/email-infrastructure',
    scope: scopes.authenticated,
    component: <EmailInfrastructure />,
  },
  templateMXRisk: {
    path: '/template-mx-risk',
    scope: scopes.authenticated,
    component: <TemplateMXRisk />,
  },
  templateMXRiskFolder: {
    path: '/template-mx-risk/:templateGroupId',
    scope: scopes.authenticated,
    component: <TemplateMXRiskFolder />,
  },
  templateMXRiskAnalyze: {
    path: ['/template-mx-risk/analyze', '/template-mx-risk/analyze/:templateGroupId'],
    scope: scopes.authenticated,
    component: <TemplateMXRiskAnalyze />,
  },
  templateMXRiskEdit: {
    path: '/template-mx-risk/edit/:id',
    scope: scopes.authenticated,
    component: <TemplateMXRiskEdit />,
  },
  templateMXRiskResult: {
    path: '/template-mx-risk/result/:id',
    scope: scopes.none,
    component: <TemplateMXRiskResult />,
  },
  profile: {
    path: '/profile',
    scope: scopes.authenticated,
    component: <Profile />,
  },
  changePassword: {
    path: '/change-password',
    scope: scopes.authenticated,
    component: <ChangePassword />,
  },
  maintenance: {
    path: '/maintenance',
    scope: scopes.none,
    component: <Maintenance />,
  },
  notFound: {
    path: '/404',
    scope: scopes.none,
    component: <NotFound />,
  },
  notificationPresets: {
    path: '/notification-presets',
    scope: scopes.authenticated,
    component: <NotificationPresets />,
  },
  monitorResult: {
    path: '/monitor-results/check/:checkId',
    scope: scopes.none,
    component: <MonitorResults />,
  },
  emailMXRisk: {
    path: '/email-mx-risk',
    scope: scopes.authenticated,
    component: <EmailMXRisk />,
  },
  emailMXRiskResult: {
    path: '/email-mx-risk/result/:id',
    scope: scopes.none,
    component: <EmailMXRiskResult />,
  },
  emailMXRiskHistory: {
    path: '/email-mx-risk/:id',
    scope: scopes.authenticated,
    component: <EmailGroupNameHistory />,
  },
  apiKeys: {
    path: '/api-keys',
    scope: scopes.authenticated,
    component: <APIKeys />,
  },
  billing: {
    path: '/billing',
    scope: scopes.authenticated,
    component: <Billing />,
  },
  subscriptionAndUsage: {
    path: '/subscription-and-usage',
    scope: scopes.authenticated,
    component: <SubscriptionAndUsage />,
  },
  pricingPlans: {
    path: '/plans',
    scope: scopes.authenticated,
    component: <PricingPlans />,
  },
  users: {
    path: '/users',
    scope: scopes.authenticated,
    component: <Users />,
  },
  dashboard: {
    path: '/',
    scope: scopes.authenticated,
    component: <Dashboard />,
  },
  emailAccounts: {
    path: '/email-accounts',
    scope: scopes.authenticated,
    component: <EmailAccounts />,
  },
  emailAccountCallback: {
    path: '/email-account-callback',
    scope: scopes.none,
    component: <EmailAccountCallback />,
  },
  deliverabilityTest: {
    path: '/deliverability-test',
    scope: scopes.authenticated,
    component: <DeliverabilityTest />,
  },
  deliverabilityTestDetails: {
    path: '/deliverability-test/:id/run/:runId',
    scope: scopes.none,
    component: <DeliverabilityTestDetails />,
  },
  deliverabilityTestStartNew: {
    path: '/deliverability-test/start-test',
    scope: scopes.authenticated,
    component: <DeliverabilityTestStartNew />,
  },
};
