import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import cn from 'classnames';

import { SmallClockIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import MenuItem from '@mui/material/MenuItem';

import Select from 'components/Select';
import ScoreCircle from 'components/ScoreCircle';
import ReportHistoryModal from 'components/ReportHistoryModal';

import './styles.scss';

const PER_PAGE = 10;

const ReportHistorySelect = ({ onSelected, className, checkHistoryItem, show }) => {
  const params = useParams();
  const location = useLocation();

  const [value, setValue] = useState(+location.hash.replace('#', '') || null);
  const [open, setOpen] = useState(false);
  const [isReportHistoryModalVisible, setIsReportHistoryModalVisible] = useState(false);

  const [page, setPage] = useState(1);

  const { data: monitorCheckHistoryList } = monitorApi.useMonitorCheckHistoryList({
    checkId: params.checkId,
    page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    if (!value && monitorCheckHistoryList) {
      if (!monitorCheckHistoryList.items.length) {
        return;
      }

      let selectedCheck = null;

      if (location.hash) {
        selectedCheck = monitorCheckHistoryList.items.find((item) => item.id === +location.hash.replace('#', ''));
      }

      if (!selectedCheck) {
        selectedCheck = monitorCheckHistoryList.items.reduce((acc, item) => (acc.checkAt >= item.checkAt ? acc : item));
      }

      setValue(selectedCheck.id);
      onSelected(selectedCheck.id);
    }
  }, [value, monitorCheckHistoryList, onSelected, location.hash]);

  const onOpenReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(true);
    setOpen(false);
  }, []);

  const onCloseReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(false);
  }, []);

  const selectItems = monitorCheckHistoryList ? monitorCheckHistoryList.items.map((item) => ({
    value: item.id,
    label: moment(item.checkAt).format('MMM DD, YYYY [at] h:mmA'),
    ...item,
  })) : [];

  const onReportHistoryItemClick = useCallback((data) => {
    setValue(data);
    onSelected(data);
    onCloseReportHistoryModal();
  }, [onSelected, onCloseReportHistoryModal]);

  if (!show) {
    return null;
  }

  return (
    <>
      {!!selectItems.length && isReportHistoryModalVisible && (
      <ReportHistoryModal
        open={isReportHistoryModalVisible}
        onClose={onCloseReportHistoryModal}
        forName="Monitor"
        items={selectItems}
        page={page}
        perPage={PER_PAGE}
        totalItems={monitorCheckHistoryList?.totalItems}
        onPageChanged={(newPage) => setPage(newPage)}
        onItemClick={(item) => onReportHistoryItemClick(item.value)}
      />
      )}
      {value && (
      <Select
        value={value}
        open={open}
        className={cn('report-history-select', className)}
        onChange={onReportHistoryItemClick}
        onClick={() => setOpen(!open)}
        items={selectItems}
        renderValue={() => {
          const date = new Date(checkHistoryItem?.checkAt);

          return (
            <div className="report-history-select-value">
              <SmallClockIcon />
              <p>{date?.toLocaleString(navigator.language)}
              </p>
            </div>
          );
        }}
      >
        {selectItems.slice(0, 10).map((item) => (
          <MenuItem key={item['@id']} value={item.value} className="report-history-select-menu-item">
            <ScoreCircle
              mxrScoreTotal={10}
              mxrScoreTotalPass={item.mxrScore}
              ratingColor={item.ratingColor}
              ratingLabel={item.ratingLabel || 'U'}
              onlyCircle
              size="tiny"
            />

            <p className="label">{item.label}</p>
          </MenuItem>
        ))}
        {monitorCheckHistoryList?.totalItems > 10 && (
        <div>
          <div className="report-history-select-line" />
          <p className="report-history-select-see-more" onClick={onOpenReportHistoryModal} aria-hidden>
            See more
          </p>
        </div>
        )}
      </Select>
      )}
    </>
  );
};

ReportHistorySelect.propTypes = {
  onSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
  checkHistoryItem: PropTypes.shape(),
  show: PropTypes.bool.isRequired,
};

ReportHistorySelect.defaultProps = {
  className: null,
  checkHistoryItem: null,
};

export default React.memo(ReportHistorySelect);
