import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { monitorApi } from 'resources/monitor';

import CircularProgress from '@mui/material/CircularProgress';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Select from 'components/Select';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const UsedMonitorsModal = ({ open, onClose, notification }) => {
  const [notificationsSelectValue, setNotificationsSelectValue] = useState(null);

  const { data: monitors, isLoading: isMonitorListLoading } = monitorApi.useMonitorListByNotification(notification.id);
  const { data: monitorNotifications, isLoading: isMonitorNotificationsLoading } = monitorApi.useMonitorNotificationList();
  const {
    mutate: deleteMonitorNotification,
    isLoading: isDeleteMonitorNotificationLoading,
  } = monitorApi.useDeleteMonitorNotification();
  const {
    mutateAsync: updateMonitor,
    isLoading: isUpdateMonitorLoading,
  } = monitorApi.useUpdateMonitor();
  const isLoading = isMonitorListLoading || isMonitorNotificationsLoading;

  const monitorsLength = notification.monitors.length;
  const otherMonitorNotifications = monitorNotifications && monitorNotifications
    .filter((monitorNotifcation) => monitorNotifcation.id !== notification.id);
  const isOtherMonitorNotificationsExist = !!otherMonitorNotifications?.length;

  useEffect(() => {
    if (!notificationsSelectValue && otherMonitorNotifications?.length) {
      setNotificationsSelectValue(otherMonitorNotifications[0].id);
    }
  }, [notificationsSelectValue, otherMonitorNotifications]);

  const onRebindAndDelete = useCallback(async () => {
    await Promise.all(monitors
      .map((monitor) => updateMonitor({ id: monitor.id, notificationId: notificationsSelectValue })));

    deleteMonitorNotification(notification.id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Notification preset has been deleted!');

        onClose();
      },
    });
  }, [deleteMonitorNotification, notification.id, onClose,
    updateMonitor, monitors, notificationsSelectValue]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={isOtherMonitorNotificationsExist
        ? 'Are you sure you want to delete this Notification Preset?'
        // eslint-disable-next-line max-len
        : `This notification is being used by ${monitorsLength} monitor${monitorsLength > 1 ? 's' : ''} and cannot be deleted.`}
    >
      <div className="used-modal-wrapper">
        {!isLoading ? (
          <div className="content">
            <p>{isOtherMonitorNotificationsExist
              // eslint-disable-next-line max-len
              ? `Please note that the deletion of this notification preset will also affect the connected monitors (${monitors.slice(0, 3).map((monitor) => monitor.title).join(', ')}${monitors.length > 3 ? ', etc...' : ''}). Please reassign them to a new preset or disconnect them before proceeding.`
              : `Monitor list: ${monitors.map((monitor) => monitor.title).join(', ')}.`}
            </p>

            {isOtherMonitorNotificationsExist && notificationsSelectValue && (
              <div className="check-select-wrapper">
                <p>Reassign to:</p>
                <Select
                  className="check-select"
                  value={notificationsSelectValue}
                  onChange={setNotificationsSelectValue}
                  items={otherMonitorNotifications.map((monitorNotification) => ({
                    value: monitorNotification.id,
                    label: monitorNotification.presetName,
                  }))}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>Select preset</p>
                      <p>
                        {otherMonitorNotifications
                          .find((selectItem) => selectItem.id === renderValue)?.presetName}
                      </p>
                    </div>
                  )}
                />
              </div>
            )}

            {isOtherMonitorNotificationsExist
              ? (
                <div className="buttons">
                  <Button color="secondaryButton" className="cancel-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="delete-button"
                    onClick={onRebindAndDelete}
                    color="error"
                    loading={isUpdateMonitorLoading || isDeleteMonitorNotificationLoading}
                  >
                    Reassign & Delete
                  </Button>
                </div>
              )
              : <Button className="close-button" onClick={onClose}>Close</Button>}
          </div>
        )
          : <CircularProgress className="loader" />}
      </div>
    </Modal>
  );
};

UsedMonitorsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.shape().isRequired,
};

export default React.memo(UsedMonitorsModal);
