import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import cn from 'classnames';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';

import {
  PlusIcon, ArrowBottomIcon, EmptyMonitorIcon,
  HistoryIcon, DotsIcon, HocScanIcon,
} from 'assets/icons';

import { emailApi } from 'resources/email';
import { monitorApi } from 'resources/monitor';

import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import Button from 'components/Button';
import ScoreCircle from 'components/ScoreCircle';
import AddHocScanModal from 'components/AddHocScanModal';
import ReportHistoryModal from 'components/ReportHistoryModal';
import Pagination from 'components/Pagination';
import DeleteModal from 'components/DeleteModal';

import useWindowDimensions from 'hooks/useWindowDimensions';

import uiNotificationService from 'services/uiNotificatuion.service';

import AddOrEditEmailInfrastructureMonitorModal from './components/AddOrEditEmailInfrastructureMonitorModal';
import EmailInfrastructureItem from './components/EmailInfrastructureItem';

import './styles.scss';

const AD_HOC_SCAN_PER_PAGE = 10;

const EmailInfrastructure = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);
  const [isAddHocScanModalVisible, setIsAddHocScanModalVisible] = useState(false);
  const [menuElement, setMenuElement] = useState(null);
  const [titleMenuElement, setTitleMenuElement] = useState(null);
  const [isReportHistoryModalVisible, setIsReportHistoryModalVisible] = useState(false);
  const [isAddEmailInfrastructureMonitorVisible, setIsAddEmailInfrastructureMonitorVisible] = useState(false);

  const [adHocScanPage, setAdHocScanPage] = useState(1);

  const [emailCheckToDelete, setEmailCheckToDelete] = useState(null);
  const [isDeleteEmailCheckModalVisible, setIsDeleteEmailCheckModalVisible] = useState(false);

  const perPage = 8;

  const mobileWidth = width <= 1000;

  const { data: emailList } = emailApi.useEmailList({
    page: adHocScanPage,
    perPage: AD_HOC_SCAN_PER_PAGE,
    emailType: 4,
  });
  const { mutate: deleteEmailCheck, isLoading: isDeleteEmailCheckLoading } = emailApi.useDeleteEmailCheck();

  const {
    data,
    isLoading,
    isFetching,
  } = monitorApi.useMonitorList({
    type: 'infrastructure',
    page,
    perPage,
  });
  const [fetchedMonitors, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isEmptyState = !isFetching && !items.length;

  useEffect(() => {
    if (fetchedMonitors) {
      setItems(fetchedMonitors);
    }
  }, [fetchedMonitors]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const onOpenHocScanHistory = useCallback((e) => {
    e.stopPropagation();

    setMenuElement(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuElement(null);
  }, []);

  const selectItems = emailList ? emailList.items.map((item) => ({
    value: item.toEmail,
    label: moment(item.insertTimestamp).format('MMM DD, YYYY [at] h:mmA'),
    time: moment(item.insertTimestamp).format('MMM DD, YYYY [at] h:mmA'),
    ...item,
  })) : [];

  const onOpenReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(true);
  }, []);

  const onCloseReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(false);
  }, []);

  const onReportHistoryItemClick = useCallback((email) => {
    if (!email) {
      return;
    }
    navigate(routes.result.path.replace(':email', email));
  }, [navigate]);

  const refreshItemList = useCallback(() => {
    searchParams.set('page', 1);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const openTitleMenu = useCallback((e) => {
    setTitleMenuElement(e.currentTarget);
  }, []);

  const closeTitleMenu = useCallback(() => {
    setTitleMenuElement(null);
  }, []);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onHistoryItemDeleteClick = useCallback((item) => {
    setIsDeleteEmailCheckModalVisible(true);
    setEmailCheckToDelete(item);
  }, []);

  const onHistoryItemDelete = useCallback(() => {
    const emailCheckId = +emailCheckToDelete['@id'].replace('/v2/emails/', '');

    deleteEmailCheck(emailCheckId, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email check has been deleted');

        setEmailCheckToDelete(null);
        setIsDeleteEmailCheckModalVisible(false);
      },
    });
  }, [emailCheckToDelete, deleteEmailCheck]);

  const EmptyState = () => (
    <div className="empty-state">
      <EmptyMonitorIcon />

      <p className="empty-state-title">Create your first monitor</p>

      <Button
        startIcon={<PlusIcon />}
        iconClassName="button-icon"
        onClick={() => setIsAddEmailInfrastructureMonitorVisible(true)}
      >
        Add Monitor
      </Button>
    </div>
  );

  return (
    <AuthorizedLayout>
      {isAddHocScanModalVisible && (
      <AddHocScanModal
        open={isAddHocScanModalVisible}
        onClose={() => { setIsAddHocScanModalVisible(false); closeTitleMenu(); }}
      />
      )}
      {!!selectItems.length && isReportHistoryModalVisible && (
      <ReportHistoryModal
        open={isReportHistoryModalVisible}
        onClose={() => { onCloseReportHistoryModal(); closeTitleMenu(); }}
        forName="account"
        items={selectItems}
        page={adHocScanPage}
        perPage={AD_HOC_SCAN_PER_PAGE}
        totalItems={emailList?.totalItems}
        onPageChanged={(newPage) => setAdHocScanPage(newPage)}
        onItemClick={(item) => onReportHistoryItemClick(item.value)}
        showEmail
        onDelete={onHistoryItemDeleteClick}
      />
      )}
      {isAddEmailInfrastructureMonitorVisible && (
      <AddOrEditEmailInfrastructureMonitorModal
        onClose={() => setIsAddEmailInfrastructureMonitorVisible(false)}
        onMonitorAdded={refreshItemList}
      />
      )}
      {isDeleteEmailCheckModalVisible && emailCheckToDelete && (
      <DeleteModal
        title={`Are you sure you want to delete email check from ${emailCheckToDelete.label}?`}
        open={isDeleteEmailCheckModalVisible}
        onClose={() => setIsDeleteEmailCheckModalVisible(false)}
        onDelete={onHistoryItemDelete}
        isDeleteLoading={isDeleteEmailCheckLoading}
      />
      )}

      <div className="email-infrastructure-wrapper">
        <div>
          <div className="title">
            <p>Email Infrastructure</p>

            {mobileWidth ? (
              <div className="mobile-buttons">
                {!isEmptyState && (
                <Button
                  startIcon={<PlusIcon />}
                  iconClassName="button-icon"
                  onClick={() => setIsAddEmailInfrastructureMonitorVisible(true)}
                />
                )}

                <div className={cn('menu-wrapper', titleMenuElement && 'menu-wrapper-active')}>
                  <IconButton onClick={openTitleMenu}>
                    <DotsIcon />
                  </IconButton>

                  <Menu
                    open={!!titleMenuElement}
                    onClose={closeTitleMenu}
                    anchorEl={titleMenuElement}
                  >
                    <MenuItem onClick={() => setIsAddHocScanModalVisible(true)} className="menu-item">
                      <HocScanIcon />
                      <p>Ad-Hoc Scan</p>
                    </MenuItem>
                    <MenuItem onClick={onOpenReportHistoryModal} className="menu-item">
                      <HistoryIcon />
                      <p>Ad-Hoc Scan History</p>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            ) : (
              <div className="buttons">
                <Button
                  onClick={() => setIsAddHocScanModalVisible(true)}
                  variant="outlined"
                  className="hoc-scan-button"
                  iconClassName="button-icon"
                >
                  <div className="hoc-scan-button-content">
                    <p>Ad-Hoc Scan</p>

                    <div onClick={onOpenHocScanHistory} aria-hidden>
                      <ArrowBottomIcon />
                    </div>
                  </div>
                </Button>

                <Menu
                  open={!!selectItems.length && !!menuElement}
                  onClose={closeMenu}
                  anchorEl={menuElement}
                >
                  <div className="hoc-scan-history-menu-content">
                    <p>Ad-Hoc Scan History</p>

                    <div className="line" />

                    <div className="hoc-scan-history-list">
                      {selectItems.slice(0, 5).map((item) => (
                        <MenuItem
                          key={item['@id']}
                          value={item.value}
                          className="hoc-scan-item"
                          onClick={() => onReportHistoryItemClick(item.toEmail)}
                        >
                          <ScoreCircle
                            mxrScoreTotal={10}
                            mxrScoreTotalPass={item.mxrScore}
                            ratingColor={item.ratingColor}
                            ratingLabel={item.ratingLabel || 'U'}
                            onlyCircle
                            size="small"
                          />

                          <div className="label">
                            <p>{item.fromEmail || 'Undelivered'}</p>
                            <p>{item.time}</p>
                          </div>
                        </MenuItem>
                      ))}
                    </div>

                    <div className="line" />

                    <p onClick={onOpenReportHistoryModal} aria-hidden className="hoc-scan-see-more">
                      See more
                    </p>
                  </div>
                </Menu>

                {!isEmptyState && (
                <Button
                  startIcon={<PlusIcon />}
                  iconClassName="button-icon"
                  onClick={() => setIsAddEmailInfrastructureMonitorVisible(true)}
                >
                  Add Monitor
                </Button>
                )}
              </div>
            )}
          </div>
        </div>

        {isEmptyState && <EmptyState />}

        {!!items.length && (
          <div className="list-wrapper">
            <div className="email-list">
              {items.map((item) => (
                <EmailInfrastructureItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  totalTests={item.totalTests}
                  testsPass={item.testsPass}
                  lastRunAt={item.lastRunAt}
                  enabled={item.status === 'active'}
                  checkItems={item.checkItems
                    .filter((checkItem) => ['email', 'smtp', 'spam', 'dns'].includes(checkItem.item))}
                  scheduleSeconds={item.scheduleSeconds}
                  notificationId={+item.notification.replace('/v2/monitor-notifications/', '')}
                  mxrScore={item.mxrScore}
                  ratingColor={item.ratingColor}
                  ratingLabel={item.ratingLabel}
                  generatedEmail={item.generatedEmail}
                  onDeleted={refreshItemList}
                />
              ))}
            </div>

            {totalItems > items.length && (
              <Pagination
                className="table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default EmailInfrastructure;
