import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  CheckIcon, MinusIcon, ArrowBottomIcon,
  ArrowTopIcon, CrossIcon, SmallCheckIcon,
} from 'assets/icons';

import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { EMAIL_REPORT_GROUPS } from 'pages/Results';

import './styles.scss';

const getChipValue = ({ groupResultsSection, mobileWidth }) => {
  if (mobileWidth) {
    if (groupResultsSection.state?.value === 'Success') {
      return <SmallCheckIcon />;
    }

    return <CrossIcon width={18} height={18} style={{ marginTop: 3, marginLeft: -2 }} />;
  }

  return groupResultsSection.state?.value;
};

const GroupResultSection = ({ groupResultsSection, groupName }) => {
  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 600;

  const isSpamAssassin = groupName === EMAIL_REPORT_GROUPS.SPAM_ASSASSIN;

  const onDescriptionOpenToggle = useCallback(() => {
    setIsDescriptionOpened(!isDescriptionOpened);
  }, [isDescriptionOpened]);

  return (
    <div className={cn('monitor-result-group-section', 'pagebreak')}>
      <div className="group-section-title">
        <div className={cn(!groupResultsSection.description && 'align-items-center')}>
          {groupResultsSection.state && (
          <div className={cn('group-status-mark', !groupResultsSection.state.status && 'group-status-mark-fail')}>
            {groupResultsSection.state.status ? <CheckIcon /> : <MinusIcon />}
          </div>
          )}

          <div className="group-section-text">
            <p>{groupResultsSection.title}</p>
          </div>
        </div>

        <div className="chip-and-arrow-wrapper">
          {groupResultsSection.state && (
          <div className="chip-wrapper">
            <Chip
              className="chip"
              label={getChipValue({ groupResultsSection, mobileWidth })}
              variant={groupResultsSection.state.value === 'Fail' ? 'filled' : 'outlined'}
              color={groupResultsSection.state.color}
            />
          </div>
          )}

          {groupResultsSection.description && !mobileWidth && (
            <IconButton onClick={onDescriptionOpenToggle}>
              {isDescriptionOpened
                ? <ArrowTopIcon className="arrow" />
                : <ArrowBottomIcon className="arrow" />}
            </IconButton>
          )}
        </div>
      </div>

      {groupResultsSection.description && mobileWidth && (
        <div className="more-info">
          <p>More info</p>

          <IconButton onClick={onDescriptionOpenToggle}>
            {isDescriptionOpened
              ? <ArrowTopIcon className="arrow" />
              : <ArrowBottomIcon className="arrow" />}
          </IconButton>
        </div>
      )}

      {isDescriptionOpened && <p className="description">{groupResultsSection.description}</p>}

      {!!groupResultsSection.rules.length && (
        <div className="rules">
          {groupResultsSection.rules.map((rule) => (
            <div className="rule-item" key={rule.title}>
              <div className="rule-item-content">
                <div>
                  <p>{rule.title}</p>

                  <p>{rule.description}</p>
                </div>

                {groupResultsSection.state && (
                <div className={cn('rules-chip-wrapper', isSpamAssassin && 'spamassassin-chip-wrapper')}>
                  <Chip
                    className={isSpamAssassin ? 'spamassassin-chip' : 'sub-chip'}
                    label={isSpamAssassin ? rule.points : getChipValue({ groupResultsSection, mobileWidth })}
                    variant={groupResultsSection.state.value === 'Fail' ? 'filled' : 'outlined'}
                    color={rule.state.color}
                  />
                </div>
                )}
              </div>

              <div className="line" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

GroupResultSection.propTypes = {
  groupResultsSection: PropTypes.shape().isRequired,
  groupName: PropTypes.string.isRequired,
};

export default React.memo(GroupResultSection);
